import { LitElement, html, css, unsafeCSS } from 'lit-element';
import { Typography, MediaQuery } from '../../styles';

class SearchArticleResult extends LitElement {

    static get properties() {
        return {
            title: { type: String },
            preview: { type: String },
            imageUrl: { type: String },
            link: {type: String }
        }
    }

    static get styles() {
        return css`
            a {
                display: flex;
                text-decoration: none;
                padding: 10px;
                margin-bottom: 20px;
                background-color: #e2e2e26e;
                border-radius: 4px;
                transition: box-shadow 0.5s;
            }

            
            a:hover {
                box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
                transition: box-shadow 0.5s;
            }

            .meta {
                flex: 3;
                margin-left: 20px;
            }

            img {
                flex: 1;
                width: 50px;
                height: 100%;
                box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
                border-radius: 4px;
            }

            

            .meta h2 {
                margin-top: 0;
                font-family: ${unsafeCSS(Typography.HeadingFontStack)};
                color: ${unsafeCSS(Typography.HeadingColor)};
            }

            .meta p {
                width: 50%;
                font-family: ${unsafeCSS(Typography.BodyFontStack)};
                color: ${unsafeCSS(Typography.HeadingColor)};
            }

            @media only screen and (max-width: 767px) {
                a {
                    flex-direction: column;
                }

                img {
                    width: 100%;
                }

                .meta {
                    margin: 0;
                    margin-top: 20px;
                }

                .meta h2 {
                    margin-bottom: 5px;
                }

                .meta p {
                    margin-top: 0;
                    width: 100%;
                }

                a:hover {
                    border: none;
                }
            }

            ${unsafeCSS(MediaQuery.TABLET)} {
                img {
                    flex: 2;
                }

                .meta {
                    flex: 4;

                }

                .meta p {
                    width: 100%;
                }
            }

            ${unsafeCSS(MediaQuery.TABLET_AND_MOBILE)} {
                a:hover {
                    box-shadow: none;
                }
            }
        `;
      }

    /**
     * Implement `render` to define a template for your element.
     *
     * You must provide an implementation of `render` for any element
     * that uses LitElement as a base class.
     */
    render(){
      /**
       * `render` must return a lit-html `TemplateResult`.
       *
       * To create a `TemplateResult`, tag a JavaScript template literal
       * with the `html` helper function:
       */
      return html`
        <a href="${this.link}">
            <img src=${this.imageUrl || ""} />
            <div class="meta">
                <h2>${this.title}</h2>
                <p>${this.preview}</p>
            </div>
        </a>
      `;
    }
  }

  customElements.define('search-article-result', SearchArticleResult);
