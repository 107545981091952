function subscribePageLoad (object) {
  function loadingAnimation() {
    object.find('.subscribe-page-input').hide();
    object.find('.subscribeButton').hide();
    object.find('.loader').show();
  }

  function submitEmail(email, tags) {
    $.ajax({
        method: "POST",
        url: "/user/email/subscribe.json",
        data: { email_user: {email: email, tags: tags } }
    }).done(function(response) {
        redirect(response.previous_link);
    });
  }

  function redirect(link) {
    var redirectUrl = location.search.split('redirect=')[1];
    if(typeof redirectUrl === 'string' && redirectUrl.includes(location.origin)) {
      location.href = redirectUrl;
    } else {
        if(typeof link === 'string') {
            location.href = link;
        } else {
            location.href = location.origin;
        }
    }
  }
  
  object.find('.subscribeButton').on('click', function () {
    var email = object.find('.subscribe-page-input').val();
    var tags = [];
    object.find("input[name='tags[]']").each(function (i,a) { tags.push($(a).val()) })
    var validation = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    if(email.length > 0 && validation.test(email)) {
      loadingAnimation();
      submitEmail(email, tags);
    }
  });
}

$(document).ready(function() {
  var formsOnPage = $('.subscribe-form-module').length;
  if(formsOnPage > 0) {
    if(formsOnPage == 1) {
        subscribePageLoad($('.subscribe-form-module'));
    } else {
        var objects = $('.subscribe-form-module');
        objects.each(function (i, object) {
          subscribePageLoad($(object));
        });
    }

  }
});
