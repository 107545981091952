import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ "toggle", "menu" ]

    toggle() {
        let classList = this.menuTarget.classList
        if(classList.contains('active')) {
            classList.remove('active')
        } else {
            classList.add('active')
        }

    }
}