$(document).on('ready', function () {
    if ($('#infinite-scrolling').size() > 0) {
        return $(window).on('scroll', function () {
            var loader = $('#infinite-scrolling-loader');
            if(window.infiniteScrollingInProgress === true) return;
            var more_posts_url;
            more_posts_url = $('.pagination a.next_page').attr('href');
            if (more_posts_url && $(window).scrollTop() > $(document).height() - $(window).height() - 60) {
                if(window.infiniteScrollingInProgress === true) return;
                window.infiniteScrollingInProgress = true;
                loader.show();
                $.ajax({
                    url: more_posts_url
                }).done(function (doc) {
                    doc = $(doc);
                    $("#infinite-scrolling").html(doc.find("#infinite-scrolling").html());
                    $(".inf-posts-wrapper").append(doc.find(".post-wrapper"));
                    sr.reveal('.post-wrapper');
                    loader.hide();
                    window.infiniteScrollingInProgress = false;
                }).fail(function (jqXHR, textStatus) {
                    console.error("Request failed: " + textStatus);
                });
            }
        });
    }
});
