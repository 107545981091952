import Vue from "vue";

Vue.component('feedback-preview', {
    template: '#feedback-preview',
    props: {
        course_id: '',
        universal_list: false,
        full_list: false,
        slug: ''

    },
    data: function () {
        return {
            feedbacks: [],
            loaded: false,
            randomId: Math.floor(Math.random() * (10000000 - 100000 + 1)) + 100000,
            areBoxedItems: true,
            offset: 0,
            totalItems: null,
            course: {}
        }
    },
    computed: {
        fullList: function () {
            return this.full_list === 'true';
        },
        fullTestimonialsLink: function () {
            return '/course/' + this.slug + '/testimonials';
        },
        courseLandingLink: function () {
            return '/course/' + this.slug;
        }
    },
    created: function () {
        this.areBoxedItems = !(device.mobile || this.universal_list);
        this.loadFeedbacks();
        this.loadCourse();
    },
    methods: {
        loadCourse: function () {
            axios.get('/api/v1/courses/' + this.slug).then(this.processLoadedCourseResult);
        },
        processLoadedCourseResult: function (res) {
            this.course = res.data.course;
        },
        loadFeedbacks: function () {
            var limit = this.limit();
            var that = this;
            axios.get('/api/v1/courses/' + this.course_id + '/feedback?limit=' + limit + '&offset=' + this.offset)
                .then(function (resp) {
                    that.renderFeedbacks(resp.data);
                    that.offset += limit;
                    if(that.fullList && that.totalItems > that.feedbacks.length) {
                        that.loadFeedbacks();
                    }
                });
        },
        renderFeedbacks: function (data) {
            this.feedbacks = this.feedbacks.concat(data.objects);
            this.totalItems = data.total;
            var that = this;

            this.feedbacks = this.feedbacks.map(function (elem) {
                return Object.assign({}, elem, {areBoxedItems: that.areBoxedItems});
            });
            this.loaded = true;

        },
        limit: function () {
            if(this.full_list) {
                return 10;
            } else {
                return  device.mobile ? 5 : 3;
            }
        },
        areBoxedItems: function () {
            if(device.mobile || this.universal_list) {
                debugger;
                return false;

            } else {

                return true;
            }
        }
    }
});

if(document.getElementById('vue-feedback-preview')) {
    var feedbackPreview = new Vue({
        el: "#vue-feedback-preview"
    });
}
