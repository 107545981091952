import { LitElement, html, css } from 'lit-element';

class SearchResults extends LitElement {

    static get styles() {
        return css`
            div {
                margin-top: 30px;
            }
        `;
      }

    /**
     * Implement `render` to define a template for your element.
     *
     * You must provide an implementation of `render` for any element
     * that uses LitElement as a base class.
     */
    render(){
      /**
       * `render` must return a lit-html `TemplateResult`.
       *
       * To create a `TemplateResult`, tag a JavaScript template literal
       * with the `html` helper function:
       */
      return html`
        <div>
            <slot></slot>
        </div>
      `;
    }
  }

  customElements.define('search-results', SearchResults);
