import { LitElement, html, css, unsafeCSS } from 'lit-element';
import { MediaQuery } from '../../styles';

class SearchComponent extends LitElement {

    static get properties() {
        return {
            query: { type: String },
            results: {
                type: Array,
                reflect: true,
                converter: {
                    toAttribute(value) {
                        return value.toString();
                    },

                    fromAttribute(value) {
                        return JSON.parse(value)
                    }
                }
            }
        }
    }

    constructor() {
        super();
        this.timer = null;
        this.query = "";
        this.results = [];

        this.queryResults = this.queryResults.bind(this);
    }

    static get styles() {
        return css`

            :host {
                display: flex;
                justify-content: center;
                margin-top: 50px;
            }
            div {
                width: 1024px;
            }

            
            form {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .search-input {
                -webkit-appearance: none;
                width: 1024px;
                height: 50px;
                border: 1px solid #d8d8d8;
                border-radius: 4px;
                font-size: 18px;
                color: #757575;
                padding-left: 16px;
                padding-right: 16px;
                outline: none;
                transition: border-color 0.5s, box-shadow 0.5s;
            }

            .search-input:focus {
                box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
                border-color: transparent;
                transition: border-color 0.5s, box-shadow 0.5s;
            }

            ${unsafeCSS(MediaQuery.MOBILE)} {
                div {
                    width: 95%;
                }

                :host {
                    margin-top: 20px;
                }
            }

            ${unsafeCSS(MediaQuery.TABLET)} {
                

                div {
                    width: 95%;
                }
            }
        `;
    }

    queryResults() {
        fetch(`/search.json?q=${this.query}`)
            .then((response) => response.json())
            .then((posts) => {
                this.results = posts;
            });
    }

    submitButton(e) {
        e.preventDefault();
        this.queryResults();
    }

    updateSearch(e) {
        this.query = e.currentTarget.value;
        window.history.replaceState({}, location.hostname, `${location.pathname}?q=${this.query}`);
        clearTimeout(this.timer);
        this.timer = setTimeout(this.queryResults, 500);
    }

    /**
     * Implement `render` to define a template for your element.
     *
     * You must provide an implementation of `render` for any element
     * that uses LitElement as a base class.
     */
    render() {
        /**
         * `render` must return a lit-html `TemplateResult`.
         *
         * To create a `TemplateResult`, tag a JavaScript template literal
         * with the `html` helper function:
         */
        return html`
        <div>
            <form @submit="${this.submitButton}">
                <input class="search-input" placeholder="Find something..." @input="${this.updateSearch}" value="${this.query}" type="search"/>
            </form>
            <search-results>
                ${this.results.map(result => html`
                    <search-article-result 
                        title="${result.title}" 
                        imageUrl="${result.featured_image}" 
                        preview="${result.preview}"
                        link="/${result.slug}" />
                `)}
            </search-results>
        </div>
      `;
    }
}

customElements.define('search-component', SearchComponent);
