import Vue from "vue";


if(document.getElementById('vue-feedback-preview-body')) {
    Vue.component('feedback-preview-body', {
        template: '#vue-feedback-preview-body',
        props: {
            feedback: Object,
            boxedItem: true
        },
        computed: {

        },
        mounted: function () {

        }
    });
}
