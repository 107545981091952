// Entry point for the build script in your package.json
import "./controllers/public";
import "@webcomponents/webcomponentsjs";
import './components/navigation/navigationMobileComponents';
import "./components/search";
import "./components/cross-page-announcements/CrossPageAnnouncementArticle";
import './public/keenio'
import 'jquery'
import 'axios'
import 'jquery-ujs'
import 'swiper'
import 'scrollreveal'
import 'tether'
import 'bootstrap'
import 'howler'
import 'vue'
import 'vue-simple-spinner'
import 'vue-form-wizard'
import 'react'
import 'react_ujs'
import "./public/axios.js"
import "./public/classie.js"
import "./public/cookie.js"
import "./public/email-subscribe.js"
import "./public/jquery.fitvids.js"
import "./public/keenio.js"
import "./public/mobile-detect.js"
import "./public/navbar.js"
import "./public/pagination.js"
import "./public/promo-header.js"
import "./public/subscribe-page.js"
import "./public/swiper.js"
import "./public/unlock_contet.js"
import "./public/vue-main.js"
import "./public/components/article-preview.js"
import "./public/components/audio-player.js"
import "./public/components/audio-preview.js"
import "./public/components/checkout-product.js"
import "./public/components/course-preview.js"
import "./public/components/featured-product.js"
import "./public/components/feedback-preview-body.js"
import "./public/components/feedback_preview.js"
import "./public/components/latest-articles.js"
import "./public/components/latest-audio.js"
import "./public/components/latest-courses.js"
import "./public/components/login.js"
import "./public/components/top-navigation.js"
import "./public/components/context_components/subscribe-button.js"

import './components/create-post-comment.jsx'
import './components/email-subsribe-form.jsx'
import './components/popup-signup-form.jsx'
import './components/post-comments.jsx'
import "./public/adaptive.background.js"
import "./public/animatedModal.js"
import "./public/tildacopyremoval.js"