function subscribeToEmailList(obj, event) {
    var emailRe = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    event.preventDefault();
    var parent = $(obj).parent();
    var input = parent.find(".email-subscribe-input");
    var value = input.val();
    var errorField = parent.find(".error-field");
    if(typeof value === 'undefined' || value === "" || value === null || !emailRe.test(value)) {
        
        errorField.show();
        input.addClass("err");

        return;
    }
    errorField.hide(); 
    input.removeClass("err");

    var subscribeForm = $(".subscribe-form");
    $('.subscribe-form-box').hide();
    $('.subscribe-form-loader').show();

    $.ajax({
        method: "POST",
        url: "/user/email/subscribe.json",
        data: { email_user: {email: value } }
    }).done(function(response) {
        var subscribeForm = $(".subscribe-form");
        $('.subscribe-form-loader').hide();
        $('.subscribe-form-done').show();
        if (typeof downloadBook !== 'undefined') {
            downloadBook(value);
        }
    });
    
}