import { LitElement, html } from 'lit-element';

class NavigationMobileComponent extends LitElement {

    createRenderRoot() {
        return this;
    }

    constructor() {
        super();
        this.opened = false;
    }

    static get properties() {
        return {
            opened: {type: Boolean}
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.onclick = () => {

            this.opened = !this.opened;
            const menu = document.getElementById("navigation-menu");

            if(this.opened) {
                menu.classList.add("navigation-menu--active");
                document.body.style.overflow = "hidden";
            } else {
                menu.classList.remove("navigation-menu--active");
                document.body.style.overflow = "";
            }
        }

    }

    render() {
        if(this.opened) {
            return html`
                <i class="fas fa-times fa-2x"></i>
            `;
        } else {
            return html`
                <i class="fas fa-bars fa-2x"></i>
            `;
        }

    }
}

customElements.define('mobile-navigation-button', NavigationMobileComponent);
