import { LitElement, html } from 'lit-element';
import {unsafeHTML} from "lit-html/directives/unsafe-html.js";

class CrossPageAnnouncementArticle extends LitElement {

    createRenderRoot() {
        return this;

    }

    constructor() {
        super();
        this.cpa = null;
    }

    static get properties() {
        return {
            cpa: {type: Object}
        }
    }

    connectedCallback() {
        super.connectedCallback();
        fetch("/api/v1/cross_page_announcements/display")
            .then((res) => res.json())
            .then((body) => {
                if(body == null) {
                    return;
                }

                this.cpa = body;
            });
    }

    render(){
        if (!this.cpa) {
            return html``;
        }

        return html`
            <div class="card">
                <div class="card-info">
                    <h3>${this.cpa.name}</h3>
                    <p>${unsafeHTML(this.cpa.body)}</p>
                </div>                     
            </div>
      `;
    }
}

customElements.define('cross-page-announcement-article', CrossPageAnnouncementArticle);
