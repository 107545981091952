$(document).ready(function() {
        if(window.location.pathname) {
            var slides = $('.swiper-slide .promo-data');
            for(var i = 0; i < slides.length; i++) {
                var jslide = $(slides[i]);
                jslide.css('margin-top', $(slides[i].parentElement).height() / 3);
                jslide.fadeIn();
            }

            $( window ).resize(function() {
                var slides = $('.swiper-slide .promo-data');
                for(var i = 0; i < slides.length; i++) {
                    var jslide = $(slides[i]);
                    jslide.css('margin-top', $(slides[i].parentElement).height() / 3);
                    jslide.fadeIn();
                }
            });

            new Swiper('.swiper-container-top', {
                // Optional parameters
                //   pagination: '.swiper-pagination',
                paginationClickable: true,
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev',
                autoplay: 5000
            });

            if($(window).width() > 768) {
                $('.swiper-button-next').show();
                $('.swiper-button-prev').show();
            }
        }
        
    });