import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "button" ]

    static values = {
        token: String,
        session: String
    }

    connect() {
        this.stripe = Stripe(this.tokenValue)
    }

    checkout() {
        this.stripe.redirectToCheckout({
            sessionId: this.sessionValue
        })
    }
}
