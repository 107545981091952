var tcri = null;
function removeTildaCopy() {
  tildaCopyRemoval();
  tcri = setInterval(tildaCopyRemoval, 100);
}

function tildaCopyRemoval() {
  let tildaCopyElement = document.getElementById("tildacopy");
  let tildaSecondCopy = document.getElementsByClassName("t-tildalabel");
  if(tildaSecondCopy.length > 0) {
    for(element of tildaSecondCopy) {
      element.remove();  
    }  
  }
  if(tildaCopyElement) {
    tildaCopyElement.remove();  
  }
  // if(!tildaCopyElement && tildaSecondCopy.length == 0) {
  //   clearInterval(tcri);
  // }
}

document.addEventListener('DOMContentLoaded', removeTildaCopy, false);