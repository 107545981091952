
if(document.getElementById('vue-latest-articles')) {
    var latestArticles = new Vue({
        el: "#vue-latest-articles",
        data: {
            articles: [],
            loaded: false,
            notNextTick: true,
            mobile: device.mobile,
            randomId: Math.floor(Math.random() * (10000000 - 100000 + 1)) + 100000,
            swiperObj: null,
            swiperOption: {
                grabCursor: true,
                centeredSlides: true,
                observeParents: true,
                observer: true,
                slidesPerView: 1.3,
                spaceBetween: 30
            }
        },
        created: function () {
            
        },
        mounted: function() {
            var that;
            that = this;
            if(device.tablet) {
                this.swiperOption.centeredSlides = false;
                this.swiperOption.slidesPerView = 2.3;
            }
            var limit = device.desktop ? 3 : 10;

            $.ajax({
                url: ('/api/v1/articles?limit=' + limit + '&sample=true'),
                success: function(res) {
                    that.articles = res.objects;
                    that.loaded = true;
                    Vue.nextTick(function () {
                        new Swiper(document.getElementById(that.randomId), that.swiperOption);
                    });
                }
            });
        }
    });
}

