var analytics = {
    sendEvent: function sendEvent(collection, event_type, event_name, meta) {
        axios.post('/api/v1/analytics/collect', {
            collection: collection,
            event_type: event_type,
            event_name: event_name,
            page_url: location.pathname,
            page_name: document.title,
            meta: meta
        });
    }
};

