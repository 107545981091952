import Vue from "vue";

if(document.getElementById('vue-audio-preview')) {
	Vue.component('audio-preview', {
    	template: '#vue-audio-preview',
    	props: {
        	audio: Object
    	}
	});
}
