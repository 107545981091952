import Vue from "vue";


Vue.component('context-subscribe-button', {
    template: '#context-subscribe-button',
    data: function () {
        return {
            mobile: false,
            subscribed: true,
        }
    },
    created: function () {
        var that = this;

        $.get('/api/v1/user/subscribed', function (data) {
            that.subscribed = data.is_subscribed;
            console.log(that.subscribed);
        });

        if (window.innerWidth >= 1024) {
            this.mobile = false;
        } else {
            this.mobile = true;
        }

        window.onresize = function () {
            if(window.innerWidth >= 1024) {
                that.mobile = false;
            } else {
                that.mobile = true;
            }
        };
    }
});

if(document.getElementById('vue-context-subscribe-button')) {
    var contextSubscribeButton = new Vue({
        el: '#vue-context-subscribe-button'
    });
}
