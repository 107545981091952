$(document).ready(function () {
    function unlockAndDownload(data) {
        mixpanel.track('product_unlocked');
        $.ajax({
            type: "POST",
            url: document.location.pathname + '/unlock',
            data: data
        }).done(function (response) {
            if (response['type'] == 'audio') {
                $("#download-now-button").fadeOut();
                $.ajax({
                    type: "GET",
                    url: response['content_link']
                }).done(function (response) {
                    var player = response['player'];
                    $('#product-content-player').append(player)
                });
            } else {
                $("#download-now-button").fadeOut();
                var link = document.createElement('a');
                link.setAttribute('target', '_blank');
                link.setAttribute('download', 'test.pdf');
                link.href = response['content_link'];
                link.click();
            }
        });
    }

    function download() {
        if($('#type-to-download').val() == 'audio') {
            $("#download-now-button").fadeOut();
            $.ajax({
                type: "GET",
                url: $('#link-to-download').val()
            }).done(function (response) {
                var player = response['player'];
                $('#product-content-player').append(player)
            });
        } else {
            $("#download-now-button").fadeOut();
            var link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.setAttribute('download', $('#filename-to-download').val());
            link.href = $('#link-to-download').val();
            link.click();
        }
    }

    if (!document.getElementById('product-page'))  return;

    $.adaptiveBackground.run({parent: '.product-header'});
    var headerContent = $('#product-header-content')[0];
    var height = $('#product-header').height() / 6;
    $(headerContent).css('margin-top', height);
    $(headerContent).css('margin-bottom', height);

    $('#download-now-button').on('click', function () {
        var emailToUnlock = $('#email-to-unlock');
        if (emailToUnlock.length > 0) {
            if (!emailToUnlock[0].checkValidity()) return;
            var email = emailToUnlock.val();
            unlockAndDownload({email: email});
        } else {
            download();
        }
    });
});

// $(document).ready(function () {
//     function unlockAndDownload(data) {
//         mixpanel.track('product_unlocked');
//         $.ajax({
//             type: "POST",
//             url: document.location.pathname + '/unlock',
//             data: data
//         }).done(function (response) {
//             if(response['type'] == 'audio') {
//                 $("#download-now-button").fadeOut();
//                 $.ajax({
//                     type: "GET",
//                     url: response['content_link']
//                 }).done(function (response) {
//                     var player = response['player'];
// //                        $(player).width('100%');
//                     $('#product-content-player').append(player)
//                 });
//             } else {
//                 var link = document.createElement('a');
//                 link.setAttribute('target', '_blank');
//                 link.setAttribute('download', 'test.pdf');
//                 link.href = response['content_link'];
//                 link.click();
//                 $('.close-animatedModal').click();
//             }
//         });
//     }
//
//
//     var headerContent = $('#product-header-content')[0];
//     var height = $('#product-header').height() / 6;
//     $(headerContent).css('margin-top', height);
//     $(headerContent).css('margin-bottom', height);
//
//     $('.product-image img').on('ab-color-found', function (ev, payload) {
//         var personalCode = Cookies.get('personal_code');
//         var email = Cookies.get('email');
//         if(personalCode == null || email == null) {
//             var animatedModalObj = $("#download-now-button").animatedModal({
//                 animatedIn: 'zoomIn',
//                 animatedOut: 'zoomOut',
//                 color: 'rgba(0, 0, 0, 0.38)',
//                 animationDuration: '.5s'
//             });
//         } else {
//             $('#animatedModal').css('display', 'none');
//             $("#download-now-button").on('click', function (e) {
//                 e.preventDefault();
//                 unlockAndDownload({
//                     email: email,
//                     personal_code: personalCode
//                 });
//             });
//         }
//     });
//
//     $('#subscribe-and-download-form').on('submit', function (e) {
//         e.preventDefault();
//         var unindexed_array = $(this).serializeArray();
//         var indexed_array = {};
//         $.map(unindexed_array, function (n) {
//             indexed_array[n['name']] = n['value'];
//         });
//         unlockAndDownload(unindexed_array);
//     })
// });