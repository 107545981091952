import Vue from "vue";


Vue.component('top-navigation', {
    template: '#top-navigation-desktop',

    data: function () {
        return {
            mobileMenu: false,
            keys: {37: 1, 38: 1, 39: 1, 40: 1},
            mobile: false,
            loaded: false,
            signedIn: false,
            user: {
                first_name: '',
                last_name: '',
                email: ''
            },
            newUserMenu: [
                {
                    name: 'Subscribe',
                    href: '/subscribe'
                },
                {
                    name: 'Log In',
                    href: '/login'
                }
            ],
            userMenu: [
                {
                    name: 'Profile',
                    href: '/users/settings'
                },
                {
                    name: 'Settings',
                    href: '/users/edit'
                },
                {
                    name: 'Log Out',
                    href: '/api/v1/user/auth/logout'
                }
            ],
            mainMenu: [
                {
                    name: 'Search',
                    href: '/search'
                },
                {
                    name: 'About',
                    href: '/about'
                },
                {
                    name: 'Courses',
                    href: '/course',
                },
                {
                    name: 'Mini Books',
                    href: '/merch/books'
                },
                {
                    name: 'Meditations',
                    href: '/merch/audio'
                }
            ]
        }
    },
    methods: {
        preventDefault: function(e) {
            e = e || window.event;
            if (e.preventDefault)
                e.preventDefault();
            e.returnValue = false;
        },
        preventDefaultForScrollKeys: function (e) {
            if (this.keys[e.keyCode]) {
                this.preventDefault(e);
                return false;
            }
        },
        disableScroll: function () {
            if (window.addEventListener) // older FF
                window.addEventListener('DOMMouseScroll', this.preventDefault, false);
            window.onwheel = this.preventDefault; // modern standard
            window.onmousewheel = document.onmousewheel = this.preventDefault; // older browsers, IE
            window.ontouchmove  = this.preventDefault; // mobile
            document.onkeydown  = this.preventDefaultForScrollKeys;
        },
        enableScroll: function () {
            if (window.removeEventListener)
                window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
            window.onmousewheel = document.onmousewheel = null;
            window.onwheel = null;
            window.ontouchmove = null;
            document.onkeydown = null;
        },
        showMobileMenu: function() {
            this.mobileMenu = !this.mobileMenu;
            if(this.mobileMenu) {
                ga('send', 'event', 'Navigation-Menu','CLICK', 'SHOW MOBILE MENU')
                this.disableScroll();
            } else {
                ga('send', 'event', 'Navigation-Menu','CLICK', 'HIDE MOBILE MENU')
                this.enableScroll();
            }
        }
    },
    created: function () {
        if(window.innerWidth >= 768) {
            this.mobile = false;
        } else {
            this.mobile = true;
        }
        var that = this;
        window.onresize = function () {
            if(window.innerWidth >= 768) {
                that.mobile = false;
            } else {
                that.mobile = true;
            }
        };

        $.ajax({
                url: '/api/v1/user/loggedin'
        }).done(function (user) {
            that.loaded = true;
            that.signedIn = user.logged_in;
            that.user = user.user;
            localStorage['logged_in'] = user.logged_in;
        });

    }
});

if(document.getElementById('vue-top-navigation')) {
    var topNavigation = new Vue({
        el: "#vue-top-navigation"
    });
}
