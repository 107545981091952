
Vue.component("login", {
  template: "#login",
  props: ["redirectto"],
  data: function () {
    return {
      firstName: "",
      loginFlow: true,
      signupFlow: false,
      extraDetailsFlow: false,
      email: "",
      password: "",
      loggedIn: false,
      previous_url: null,
      formErrors: null,
      user: null,
    };
  },
  methods: {
    renderLogin: function () {
      this.loginFlow = true;
      this.signupFlow = false;
      this.extraDetailsFlow = false;
    },
    renderSignUp: function () {
      this.loginFlow = false;
      this.signupFlow = true;
      this.extraDetailsFlow = false;
    },
    renderExtraDetailsFlow: function () {
      this.loginFlow = false;
      this.signupFlow = false;
      this.extraDetailsFlow = true;
    },
    diactivateButton: function (button) {
      button.disabled = true;
      button.classList.add("active");
    },
    activateButton: function (button) {
      button.disabled = false;
      button.classList.remove("active");
    },
    clearErrors: function () {
      this.formErrors = null;
    },
    redirect: function (url) {
      if (typeof this.redirectto !== "undefined" && this.redirectto) {
        window.location.replace(this.redirectto);
      } else {
        window.location.replace(url);
      }
    },
    setUserResponse: function (data) {
      this.user = data.user;
      this.loggedIn = data.logged_in;
      this.previous_url = data.previous_url;
    },
    nextStepCalculation: function () {
      if (this.loggedIn && this.user.email !== "empty") {
        this.redirect(this.previous_url);
      } else if (this.user.email === "empty") {
        this.renderExtraDetailsFlow();
      }
    },
    saveExtraData: function (event) {
      var that = this;
      var button = event.target;
      this.diactivateButton(button);
      axios
        .put("/api/v1/user", {
          email: this.email,
        })
        .then(function (response) {
          that.setUserResponse(response.data);
          that.clearErrors();
          that.nextStepCalculation();
          that.activateButton(button);
        })
        .catch(function (error) {
          that.formErrors = error.response.data.errors;
          that.activateButton(button);
        });
    },
    signUp: function (event) {
      var that = this;
      var button = event.target;
      this.diactivateButton(button);
      axios
        .post("/api/v1/user/auth/signup", {
          user_id: this.email,
          password: this.password,
          first_name: this.firstName,
        })
        .then(function (response) {
          that.setUserResponse(response.data);
          that.clearErrors();
          that.nextStepCalculation();
          that.activateButton(button);
        })
        .catch(function (error) {
          that.formErrors = error.response.data.errors;
          that.activateButton(button);
        });
    },
    login: function (event) {
      var that = this;
      var button = event.target;
      this.diactivateButton(button);
      axios
        .post("/api/v1/user/auth/login", {
          user_id: this.email,
          password: this.password,
        })
        .then(function (response) {
          that.setUserResponse(response.data);
          that.nextStepCalculation();
          that.activateButton(button);
        })
        .catch(function (error) {
          that.formErrors = error.response.data.errors;
          that.activateButton(button);
        });
    },
  },
  created: function () {
    var that = this;
    if (window.location.search.substr(1).indexOf("new") >= 0) {
      this.renderSignUp();
    }
    axios.get("/api/v1/user/loggedin").then(function (response) {
      that.setUserResponse(response.data);
      that.nextStepCalculation();
    });
  },

  mounted: function () {},
});

if (document.getElementById("vue-login")) {
  var loginVue = new Vue({
    el: "#vue-login",
  });
}
