
if(document.getElementById('vue-latest-audio')) {
    var latestArticles = new Vue({
        el: "#vue-latest-audio",
        data: {
            objects: [],
            loaded: false,
            notNextTick: true,
            mobile: false,
            randomId: Math.floor(Math.random() * (10000000 - 100000 + 1)) + 100000,
            swiperObj: null,
            swiperOption: {
                grabCursor: true,
                centeredSlides: true,
                observeParents: true,
                observer: true,
                slidesPerView: 1.3,
                spaceBetween: 30
            }
        },
        created: function () {
        },
        mounted: function() {
            var that;
            that = this;

            if(device.tablet) {
                this.swiperOption.centeredSlides = false;
                this.swiperOption.slidesPerView = 2.3;
            }

            var limit = this.mobile ? 10 : 3;

            $.ajax({
                url: ('/api/v1/product?limit=' + limit + '&type=audio'),
                success: function(res) {
                    that.objects = res.objects;
                    that.loaded = true;
                    Vue.nextTick(function () {
                        new Swiper(document.getElementById(that.randomId), that.swiperOption);
                    });
                }
            });
        }
    });
}

