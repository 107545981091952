import React from "react";

class EmailSubscribeForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            requestInProgress: false,
            subscribed: false
        }
    }

    updateEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    subscribe(e) {
        e.preventDefault();

        axios.post("/api/v1/subscribers", {
            email: this.state.email
        }).then((resp) => {
            this.setState({
                subscribed: true
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    subscribedForm() {
        return (<form className={'form flex-inline top-margin-1 '} onSubmit={(e) => this.subscribe(e)}>
            <input type={'email'} placeholder={'Email address'} onChange={evt => this.updateEmail(evt)}/>
            <button type={'submit'}>Subscribe</button>
        </form>)
    }

    succcessMessage() {
        return (
            <div className={'title accent-color'} style={{margin: "1em 0"}}>
                Thank you for sticking with us.
            </div>
        )
    }

    renderFormBody() {
        if (this.state.subscribed) {
            return this.succcessMessage();
        } else {
            return this.subscribedForm();
        }
    }

    render() {
        return (
            <div className={'row card-old'} style={{padding: '1em'}}>
                <div className={'header'}>
                    Stay in touch <i className="fas fa-envelope brand-color-animation" aria-hidden="true" />
                </div>
                <div className={'title'}>
                    Leave your email to stay in touch with relevant tips, practices and meditations.
                </div>

                {this.renderFormBody()}

                <div className={'small-subtitle'}>
                    You can unsubscribe any time. Your privacy is one of our great values, we keep your information safe.
                </div>
            </div>
        )
    }
}