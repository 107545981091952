import Vue from "vue";

Vue.component('audio-player', {
    template: '#audio-player',
    props: ['link', 'title'],
    data: function () {
        return {
           playing: false,
           sound: null,
           currentPosition: 0,
           progressTimeout: null,
           midEventSent: false,
           almostEndEventSent: false
        }
    },
    methods: {
        play: function() {
            if(this.sound.seek() == 0) {
                ga('send', 'event', {
                    eventCategory: 'Audio',
                    eventAction: 'Started Playing',
                    eventLabel: this.title
                });
            }
            this.sound.play();
            this.playing = true;
        },
        pause: function() {
            this.playing = false;
            this.sound.pause();
        },
        stop: function() {
            this.playing = false;
            this.sound.stop();
            this.sound.seek(this.sound.duration());
        },
        fastForward: function() {
            var position = this.sound.seek() + 15;

            if(this.sound.duration() <= position || !this.sound.playing()) {

            } else {
                this.sound.seek(position);
            }
        },
        fastBackward: function() {
            var position = this.sound.seek() - 15;
            if(this.sound.playing()) {
                this.sound.seek(position > 0 ? position : 0);
            }

        },
        applyBackgroundProgress: function (full, current) {

           var filledBg =Math.round((current/ full)* 100);
           var unfilledBg = 100 - filledBg;

           if(this.$el.getElementsByClassName('audio-player')[0].className.match(/current-progress-\d{1,2}/)) {
            this.$el.getElementsByClassName('audio-player')[0].className =
                this.$el.getElementsByClassName('audio-player')[0].className.replace(/current-progress-\d{1,2}/g, 'current-progress-' + filledBg);
           } else {
            this.$el.getElementsByClassName('audio-player')[0].classList.add('current-progress-' + filledBg);
           }

           if(filledBg >= 50 && !this.midEventSent ) {

                ga('send', 'event', {
                    eventCategory: 'Audio',
                    eventAction: '50% Played',
                    eventLabel: this.title
                });
               this.midEventSent = true;
           } else if(filledBg >= 90 && !this.almostEndEventSent) {
                ga('send', 'event', {
                    eventCategory: 'Audio',
                    eventAction: '90% Played',
                    eventLabel: this.title
                });
                this.almostEndEventSent = true;
           }
        },
        setupProgressTimeout: function () {
            var that = this;
            this.progressTimeout = setTimeout(function () {
                that.currentPosition = that.sound.seek();
                that.applyBackgroundProgress(that.sound.duration(), that.sound.seek());
                that.setupProgressTimeout();

            }, 100);
        }
    },
    created: function() {
        var that;
        that = this;
        this.sound = new Howl({
            src: [this.link],
            html5: true
        });
        this.sound.on('play', function () {
            that.currentPosition = that.sound.seek();
            that.applyBackgroundProgress(that.sound.duration(), that.sound.seek());
            that.setupProgressTimeout();
        });
        this.sound.on('pause', function () {
            clearTimeout(that.progressTimeout);
            that.progressTimeout = null;
            that.currentPosition = that.sound.seek();
            that.applyBackgroundProgress(that.sound.duration(), that.sound.seek());
        });
        this.sound.on('end', function () {
            clearTimeout(that.progressTimeout);
            that.progressTimeout = null;
            that.currentPosition = that.sound.duration();
            that.playing = false;
            that.applyBackgroundProgress(100, 100);
            ga('send', 'event', {
                eventCategory: 'Audio',
                eventAction: 'Finished Playing',
                eventLabel: that.title
            });
        });
        this.sound.on('stop', function () {
            clearTimeout(that.progressTimeout);
            that.progressTimeout = null;
            that.currentPosition = that.sound.seek();
            that.applyBackgroundProgress(that.sound.duration(), that.sound.seek());
        });
    }
});


if(document.getElementById('vue-audio-player')) {
    var audioPlayerVue = new Vue({
        el: "#vue-audio-player"
    });
}
