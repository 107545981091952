import React from "react";

class PopupSingupFlow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            firstName: '',
            loginState: false,
            formErrors: []
        };
    }

    updateEmail(evt) {
        this.setState({
            email: evt.target.value
        });
    }

    updatePassword(evt) {
        this.setState({
            password: evt.target.value
        });
    }

    updateFirsName(evt) {
        this.setState({
            firstName: evt.target.value
        });
    }

    createAccount() {
        axios.post('/api/v1/user/auth/signup', {
            user_id: this.state.email,
            password: this.state.password,
            first_name: this.state.firstName
        }).then(function (response) {
            location.reload();
        }).catch(function (error) {
            this.setState({formErrors: error.response.data.errors});
        })
    }

    login() {
        axios.post('/api/v1/user/auth/login', {
            user_id: this.state.email,
            password: this.state.password
        }).then(function (response) {
            location.reload();
        }).catch(function (error) {
            this.setState({formErrors: error.response.data.errors});
        });
    }

    submitButton() {
        if(this.state.loginState) {
            return <button type="submit" className={'green'} onClick={this.login.bind(this)} form="loginform">&nbsp;Log In&nbsp;</button>
        } else {
            return <button type="submit" className={'green'} onClick={this.createAccount.bind(this)} form="loginform">&nbsp;Sign up&nbsp;</button>
        }
    }

    switchState() {
        this.setState(prevState => ({
            loginState: !prevState.loginState
        }));
    }

    stateSwitchMessage() {
        if(!this.state.loginState) {
            return  <div className={'subtitle center'}>
                Already a member? <a onClick={this.switchState.bind(this)}>Sign in</a>
            </div>
        } else {
            return  <div className={'subtitle center'}>
                New to the community? <a onClick={this.switchState.bind(this)}>Create an account</a>
            </div>
        }
    }

    headerMessage() {
        if (this.state.loginState) {
            return "Welcome back"
        } else {
            return "Create an account to leave a response"
        }
    }

    close() {
        $('#signup-popup').hide();
    }

    render() {
        return <div className={'popup-signup-flow'}>
                <div className={'inner-popup'}>
                    <i className="fas fa-close close-popup" onClick={this.close.bind(this)} aria-hidden="true"/>
                    <div className={'header'} style={{textAlign: 'center', display: 'block'}}>
                        {this.headerMessage()}
                    </div>
                    <br/>
                    <div className={'subtitle center'}>
                        Responses keep conversation moving. Let's raise awareness and keep moving knowledge forward.
                    </div>
                    <br/>
                    {this.stateSwitchMessage()}
                    <br/>
                    <div className={'form'}>
                        {!this.state.loginState &&
                            <input placeholder="your name" type="text" value={this.state.name} onChange={evt => this.updateFirsName(evt)}/>
                        }
                        <input placeholder="your email" type="email" value={this.state.email} onChange={evt => this.updateEmail(evt)}/>
                        <input placeholder="your password" type="password" value={this.state.password} onChange={evt => this.updatePassword(evt)}/>
                        <div className={'flex-wrapper flex-row center'} style={{margin: 0}}>
                            {this.submitButton()}
                        </div>
                        <div className={'flex-wrapper flex-row stretch'} style={{margin: 0}}>
                          
                        </div>
                    </div>
                </div>
            </div>
    }


}