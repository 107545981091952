import Vue from "vue";

Vue.component('checkout-product', {
    template: '#checkout-product',
    props: ['price_id', 'course_slug', 'stripe_key'],
    data: function () {
        return {
            step: 1,
            errorMsg: null,
            cardName: '',
            cardEmail: '',
            discountCode: null,
            user_logged_in: false,
            successDiscountCode: null,
            discountCheckInProgress: false,
            discountError: null,
            discountApplied: false,
            discountedPrice: null,
            productType: 'course',
            showDiscountField: false,
            course: {
                name: '',
                successfully_bought_message: '',
                portrait_image: '',
                slug: '',
                bought: false
            },
            price: {
                price_in_pence: 0,
                title: ''
            },
            loaded: false,
            stripeActive: false,
            paypalActive: false,
            paymentInProgress: false,
            paymentMethods: [
                {
                    workingTitle: 'stripe',
                    displayedTitle: 'Pay with Card',
                    dusplayedSubTitle: 'secured by Stripe',
                    choosen: false,
                    iconUrl: 'https://storage.googleapis.com/cdn.womangic.com/static/images/bank-card.png'
                }//,
                // {
                //     workingTitle: 'paypal',
                //     displayedTitle: 'Pay with Paypal',
                //     dusplayedSubTitle: 'Secured by Paypal',
                //     choosen: false,
                //     iconUrl: 'https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_200x51.png'
                // }
            ],
            stripe: null
        }
    },
    computed: {
        xeConverter: function () {
            var priceToConvert = this.discountApplied ? this.discountedPoundPrice : this.poundPrice;
            
            return "http://www.xe.com/currencyconverter/convert/?Amount=" + priceToConvert + "&From=GBP&To=USD";
        },
        productName: function () {
            return this.course.name;
        },
        currentPayableAmount: function () {
            return this.discountedPrice !== null ? this.discountedPoundPrice : this.poundPrice;
        },
        poundPrice: function () {

            return this.price.amount_in_cents / 100;
        },
        courseSlug: function () {
            return '/' + 'course' + '/' + this.course.slug;
        },
        discountedPoundPrice: function () {
            if(this.discountedPrice === null) return '';
            return this.discountedPrice / 100;
        }
    },
    created: function () {
        var that = this;
        $.when($.ajax('/api/v1/course?slug=' + this.course_slug), $.ajax('/api/v1/price/' + this.price_id), $.ajax('/api/v1/user/loggedin')).done(function (course, price, userData) {
            var user = userData[0];
            if(user.logged_in && user.user.email !== 'empty') {
                that.cardEmail = user.user.email;
            }
            that.course = course[0].course;
            that.price = price[0].price;
            that.user_logged_in = user.logged_in;
            analytics.sendEvent('VIEW', 'checkout_module', 'Checkout Page Loaded',
                {
                    product_type: that.product_type,
                    product_name: that.product_name

                });
            that.loaded = true;
        });

    },
    methods: {
        applyDiscount: function () {
            var that = this;
            that.discountCheckInProgress = true;
          $.get('/api/v1/discount?code=' + this.discountCode + '&id=' + this.price_id).done(function (result) {
              that.discountError = '';
              that.discountedPrice = result.price;
              that.discountCheckInProgress = false;
              that.discountApplied = true;
              that.successDiscountCode = that.discountCode;
              analytics.sendEvent('DISCOUNT_CODE', 'checkout_module', 'Discount applied', {
                  product_type: that.product_type,
                  product_name: that.product_name

              });
          }).fail(function (error) {
             if(error.status === 404) {
                that.discountError = 'Discount Code does not exist';
             }
             that.discountCheckInProgress = false;
          });
        },
        tabTwoSetup: function () {
            var choosen = false;
            var that = this;
            this.paymentMethods.forEach(function (method) {
                if(method.choosen) {
                    choosen = true;
                    analytics.sendEvent('SELECT_PAYMENT_METHOD', 'checkout_module',
                        'Payment Method ' + method.workingTitle + ' Chosen',
                        {
                            payment_method: method.workingTitle,
                            product_type: that.product_type,
                            product_name: that.product_name
                        });
                    switch (method.workingTitle) {
                        case 'stripe':
                            ga('send', 'event', {
                                eventCategory: 'CHECKOUT',
                                eventAction: 'STRIPE_rendered',
                                eventLabel: this.course_slug
                            });
                            that.stripeActive = true;
                            that.paypalActive = false;
                            that.setupStripe();
                            break;
                        case 'paypal':
                            ga('send', 'event', {
                                eventCategory: 'CHECKOUT',
                                eventAction: 'PAYPAL_rendered',
                                eventLabel: this.course_slug
                            });
                            that.paypalActive = true;
                            that.stripeActive = false;
                            that.setupPaypal();
                            break;
                    }
                }
            });

            if(!choosen) {
                this.errorMsg = 'Please choose a payment method';
                return false;
            } else {
                this.errorMsg = '';
                return true;
            }
        },
        setupPaypal: function () {
            var that = this;
            paypal.Button.render({

                env: 'sandbox', // Or 'sandbox',
                client: {
                    sandbox:    'xxxxxxxxx',
                    production: 'xxxxxxxxx'
                },
                commit: true,
                payment: function(data, actions) {
                    return actions.payment.create({
                        payment: {
                            transactions: [
                                {
                                    amount: { total: (that.price.amount_in_cents / 100), currency: 'GBP' }
                                }
                            ]
                        }
                    });
                },

                onAuthorize: function(data, actions) {
                    return actions.payment.execute().then(function(payment) {

                        console.log(payment);
                    });
                }

            }, '#paypal-button');
        },
        nextStep: function () {
            ga('send', 'event', {
                eventCategory: 'CHECKOUT',
                eventAction: 'step_' + this.step + '_complete',
                eventLabel: this.course_slug
            });

            this.step += 1;

            if(this.step === 2) {
                this.tabTwoSetup();
            }
        },
        setupStripe: function () {
            var stripe = Stripe(this.stripe_key);
            var elements = stripe.elements();
            var that = this;
            var style = {
                base: {
                    color: '#32325d',
                    lineHeight: '24px',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#aab7c4'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            };
            var card = elements.create('card', {style: style, hidePostalCode: true});
            card.mount('#card-element');
            var form = document.getElementById('payment-form');
            form.addEventListener('submit', function (event) {
                event.preventDefault();
                that.paymentInProgress = true;
                stripe.createToken(card, {
                    name: that.cardName
                }).then(function (result) {
                    if (result.error) {
                        that.errorMsg = result.error.message;
                        that.paymentInProgress = false;

                    } else {
                        that.errorMsg = '';
                        analytics.sendEvent('PREPROCESS_PURCHASE', 'checkout_module',
                            'Preprocessing Card Data',
                            {
                                payment_method: 'stripe',
                                product_type: that.product_type,
                                product_name: that.product_name,
                                purchase_type: 'card'
                            });
                        // Send the token to your server
                        axios.post('/api/v1/payment/stripe/process', {
                            token: result.token.id,
                            email: that.cardEmail,
                            name: that.cardName,
                            type: 'course',
                            discount_code: that.successDiscountCode,
                            price_id: that.price_id,
                            slug: that.course_slug
                        }).then(function (result) {
                            that.nextStep();
                        }).catch(function (error) {
                            that.errorMsg = error.response.data.message;
                            that.paymentInProgress = false;
                        });
                    }
                });
            });

        },

        selectMethod: function (selectedMethod) {
            this.paymentMethods.forEach(function (method) {
                if (method.workingTitle === selectedMethod) {
                    method.choosen = true;

                } else {
                    method.choosen = false;
                }
            });
            this.nextStep();
        }
    }
});

if (document.getElementById('vue-checkout-product')) {
    var contextSubscribeButton = new Vue({
        el: '#vue-checkout-product'
    });
    Vue.use(VueFormWizard);
}