import Vue from "vue";

Vue.component('featured-products', {
    template: '#featured-product',
    data: function () {
        return {
            product: {
                product_image: {
                    url: ''
                }
            },
            redirectUrl: '',
            loaded: false,
            link: {
                text: null,
                url: null
            }
        }
    },
    created: function() {
        var that;
        that = this;

        $.when($.ajax({
            url: '/api/v1/user/subscribed'
        }), $.ajax({
                url: '/api/v1/product/featured'
        })).done(function(subscribedData, featuredArticleData) {
            that.product = featuredArticleData[0].product;
            that.redirectUrl = featuredArticleData[0].redirect_url;
            if(subscribedData[0].is_subscribed) {
                that.link.url = featuredArticleData[0].redirect_url;
                that.link.text = "GET IT NOW";
            } else {
                that.link.url = "/subscribe?redirect=" + featuredArticleData[0].redirect_url;
                that.link.text = "SUBSCRIBE AND DOWNLOAD";
            }
            that.loaded = true;
        });


    }
});

if(document.getElementById('vue-featured-products')) {
    featuredProductsVue = new Vue({
        el: "#vue-featured-products"
    });
}



