import React from "react"

class CreatePostComment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            body: ''
        };
    }

    clickForm() {
        if (JSON.parse(localStorage['logged_in'])) {
            this.setState({
                expanded: true
            });
        } else {
            $('#signup-popup').show();
        }
    }

    updateBody(evt) {
        this.setState({
            body: evt.target.value
        });

    }

    sendResponse() {
        this.setState({
            expanded: false
        });
        var that = this;
        axios.post(`/api/v1/articles/${this.props.articleId}/comments/`, {
            body: this.state.body
        }).then(() => {
            that.setState({
                body: ''
            })
        });
    }

    render() {
        return <div>
            <h3 className={'header'}>
                Responses
            </h3>
            {!this.state.expanded &&
            <div className={'card clickable  card--vertical'} onClick={this.clickForm.bind(this)}>
                <div className={'title'} style={{'padding': '1em', color: 'grey', 'fontStyle': 'italic'}}>
                    <i className="fas fa-comment" aria-hidden="true"></i> Write a response...
                </div>
            </div>
            }

            {this.state.expanded &&
            <div className={'card  card--vertical'} style={{padding: '1em'}}>
                <textarea rows={6} value={this.state.body} onChange={evt => this.updateBody(evt)} style={{
                    width: '100%',
                    border: 'none',
                    resize: 'none'
                }}/>
                <button className={'title'} onClick={this.sendResponse.bind(this)}>Send</button>
            </div>
            }
        </div>
    }


}
