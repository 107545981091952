import Vue from "vue";

if(document.getElementById('vue-course-preview')) {
	Vue.component('course-preview', {
    	template: '#vue-course-preview',
    	props: {
        	object: Object
    	}
	});
}
