import React from "react";

class PostComments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: []
        };
        axios
            .get(`/api/v1/articles/${props.articleId}/comments`)
            .then((response) => {
                this.setState({
                    comments: JSON.parse(response.data.comments)
                });

            });

        setInterval(() => {
            axios
                .get(`/api/v1/articles/${props.articleId}/comments`)
                .then((response) => {
                    this.setState({
                        comments: JSON.parse(response.data.comments)
                    });

                });
        }, 10000)


    }
    render() {
        return <div>
            {this.state.comments.map((comment, i) => {
                return <div className={'card'} style={{padding: '1em'}}>
                        <div className={'title'}>
                            {comment.body}
                        </div>
                        <br/>
                        <div className={'subtitle'}>
                            {`by ${comment.user.first_name}`}
                        </div>
                    </div>
            })
            }
                </div>
    }
}